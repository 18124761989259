import api from './api'
import storage from './storage'
import { banks } from './logoBank.json'

const formatBank = () => {
  const listBank = []
  for (const key in banks) {
    if (Object.prototype.hasOwnProperty.call(banks, key)) {
      listBank.push({
        label: key,
        value: key,
      })
    }
  }
  return listBank
}

export {
  api, storage, formatBank,
}
